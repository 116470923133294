import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Row, Col, Label, Button,
    Card, CardBody, InputGroup, FormFeedback, Form
    , Input, Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import TableContainer from '../../components/Common/TableContainer';
import TableContainer1 from '../../components/Common/Teblecontainer1';
import * as Yup from "yup";
import '../../components/Common/common.scss'
//i18n
import { withTranslation } from "react-i18next";
// const inputRef = useRef(null);
import user1 from "../../assets/images/Young Boy.jpg";
import { useReactToPrint } from 'react-to-print';
import './reportlist.scss'
import { getLoggedInUser, getRefreshToken } from "helpers/fakebackend_helper";
import axios from "axios";
import { API, ENDPOINT } from '../Authentication/api/index';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Logout from "pages/Authentication/Logout";
import Spinners from "../../components/Common/Spinner";
import Select from "react-select";
import { useFormik } from "formik";
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { drawLine } from '@progress/kendo-drawing';
import '@progress/kendo-theme-default/dist/all.css';
import ApproveModal from "components/Common/ApproveModel";
import RejectModal from "components/Common/RejectModel";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Margin } from "@mui/icons-material";

const ReportList = props => {


    const pdfExportComponent = useRef(null);
    const [pdfBlob, setPdfBlob] = React.useState(null);
    const navigate = useNavigate()
    const [reportStatus, setReportStatus] = useState('');

    const location = useLocation();
    const user = getLoggedInUser()
    const [templateData, setTemplateData] = useState([]);
    const [dopplerData, setDopplerData] = useState([]);
    const [leftventricleData, setLeftVentricleData] = useState([]);
    const [impressionData, setImpressionData] = useState([]);
    const [defaultvalues, setDefaultValues] = useState([]);
    const [rptid, setRptid] = useState();
    const contentToPrint = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [drfilter, setDrFilter] = useState([]);
    const [selectedreferrername, setselectedReferrerName] = useState({ label: 'Select', id: 0 });
    const [modal, setModal] = useState(false);
    const [reffererOptions, setReffererOptions] = useState([]);

    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);

    const [logoVisibility, setLogoVisibility] = useState(false);
    const [signVisibility, setSignVisibility] = useState(true);

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [fontBase64, setFontBase64] = useState('');
    const regex = /<strong><em><u><p>(.*?)<\/p><\/u><\/em><\/strong>/;
    const regex1 = /<p>(.*?)<\/p>/;
    const regex2 = /<u>(.*?)<\/u>/;
    const regex3 = /<em>(.*?)<\/em>/;
    var fileName;

    const loadFontAsBase64 = (fontUrl) => {
        return new Promise((resolve, reject) => {
            fetch(fontUrl)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.arrayBuffer();
                })
                .then(buffer => {
                    const base64Font = btoa(String.fromCharCode(...new Uint8Array(buffer)));
                    resolve(`data:font/truetype;base64,${base64Font}`);
                })
                .catch(error => reject(error));
        });
    };
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",

        onBeforePrint: () => {
            let temp = document.querySelectorAll(".printfooter");
            // alert(temp.length)
            for (let i = 0; i < temp.length; i++) {
                if ((i + 1) == temp.length)
                    temp[i].style.display = "block";

            }
        },
        // onAfterPrint: () => navigate("/billing-list"),
        removeAfterPrint: true,
    });
    useEffect(() => {
        if (location?.state) {
            if (location?.state?.rpt_id) {
                setRptid(location?.state?.rpt_id)
            }
        }
    }, [location])

    useEffect(() => {
        if (rptid != null && rptid != undefined && rptid != "") {
            const data = {
                "user_id": Number(user.uid),
                "rpt_id": Number(rptid)
            }
            editLoadReportView(data)
            getHospitalList()
        }
    }, [rptid])

    const formatValue = (value) => {

        if (value != "" && value != undefined && value != null && value != "undefined" && !isNaN(value)) {
            return Number.isInteger(value) ? value?.toFixed(1) : value?.toFixed(2);
        }
        else
            return value
        // Check if the value is an integer

    };

    const getHospitalList = async () => {
        // setList(data);
        // setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETMASTERS, data, config);
            // setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getHospitalList();
                    }
                }
            }

            if (response?.data?.body?.DefaultValues) {
                setDefaultValues(response.data.body.DefaultValues);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            // setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    const editLoadReportView = async (data) => {
        setLoading(true)
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTENTRYEDITLOAD, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await editLoadReportView(data);
                    }
                }
            }

            if (response?.data?.body) {

                setTemplateData(response.data.body?.templateData);
                setDopplerData(response.data.body?.dopplerData);
                setLeftVentricleData(response.data.body?.leftventricleData);
                setImpressionData(response.data.body?.impressionData);
                setReportStatus(response.data.body?.templateData[0]?.rpt_status)
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            // setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }


    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Report View';
        getPrintSettings()
    }, []);
    //meta title
    document.title = "ReportList | Care AKPS";
    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        DIMENSIONS
                    </div>
                ),
                accessorKey: 'dimension',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div style={{fontFamily:'DejaVu Sans Condensed Bold'}} className="text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                header: (
                    <div className="text-center">
                        Value
                    </div>
                ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        <div className="text-center">

                            <Row>

                                <div style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{incm + 'cm'}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center">
                        NORMAL
                    </div>
                ),
                accessorKey: 'normal',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const normal = cellProps.row.original.normal;
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <div className="text-right">
                                <Row>

                                    <div style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{normal}</div>
                                </Row>


                            </div >



                        </div>




                    );
                }
            },
        ],
        []
    );
    const columns6 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        DIMENSIONS
                    </div>
                ),
                accessorKey: 'dimension',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="d-flex text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                header: (
                    <div className="text-center">
                        Value
                    </div>
                ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        <div style={{fontFamily:'DejaVu Sans Condensed Bold'}} className="text-center ">

                            <Row>

                                <div>{incm + "cm"}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center">
                        NORMAL
                    </div>
                ),
                accessorKey: 'normal',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const normal = cellProps.row.original.normal;
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <div className="text-right">
                                <Row>

                                    <div>{normal}</div>
                                </Row>


                            </div >



                        </div>




                    );
                }
            },
        ],
        []
    );
    const columns1 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        LEFT VENTRICLE
                    </div>
                ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="d-flex text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                header: (
                    <div className="text-center">
                        Value
                    </div>
                ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        // <div className="d-flex align-items-center">
                        <div style={{fontFamily:'DejaVu Sans Condensed Bold'}} className="text-center">

                            <Row>

                                <div>{incm}</div>
                            </Row>

                        </div >
                    );
                },
            },

            {
                header: (
                    <div className="text-center">
                        Normal
                    </div>
                ),
                accessorKey: 'normal',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const normal = cellProps.row.original.normal1;
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <div className="text-right">
                                <Row>

                                    <div>{normal}</div>
                                </Row>


                            </div >



                        </div>




                    );
                }
            },

        ],
        []
    );
    const columns7 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        RIGHT VENTRICLE
                    </div>
                ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="d-flex text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                header: (
                    <div className="text-center">
                        In cm
                    </div>
                ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        // <div className="d-flex align-items-center">
                        <div style={{fontFamily:'DejaVu Sans Condensed Bold'}} className="text-center">

                            <Row>

                                <div>{incm}</div>
                            </Row>

                        </div >
                    );
                },
            },

            {
                header: (
                    <div className="text-center">
                        NORMAL
                    </div>
                ),
                accessorKey: 'normal',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const normal = cellProps.row.original.normal1;
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <div className="text-right">
                                <Row>

                                    <div>{normal}</div>
                                </Row>


                            </div >



                        </div>




                    );
                }
            },

        ],
        []
    );
    const columns3 = useMemo(
        () => [
            {
                // header: (
                //     <div className="text-center">
                //         DIMENSIONS
                //     </div>
                // ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="d-flex text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                // header: (
                //     <div className="text-left">
                //         In cm
                //     </div>
                // ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-left">

                            <Row>

                                <div className="align-items-left">{incm}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                // header: (
                //     <div className="text-right">
                //         NORMAL
                //     </div>
                // ),
                width: '20%',

                accessorKey: 'normal',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const normal = cellProps.row.original.normal;


                    return (
                        // <div className="d-flex align-items-center">

                        <Row>

                            <div className="text-left">{normal}</div>
                        </Row>


                    );
                },
            },
            {
                // header: (
                //     <div className="text-center">
                //         RIGHT VENTRICLE
                //     </div>
                // ),
                accessorKey: 'dimensions1',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const normal = cellProps.row.original.dimensions1;
                    return (
                        <div className="d-flex text-left">
                            <Row>

                                <div>{normal}</div>
                            </Row>





                        </div>




                    );
                }
            },

        ],
        []
    );
    const columns4 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        LEFT VENTRICLE
                    </div>
                ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },


        ],
        []
    );
    const columns5 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        IMPRESSION
                    </div>
                ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },


        ],
        []
    );
    const columns2 = useMemo(
        () => [
            {
                header: (
                    <div className="text-left">
                        VALVE
                    </div>
                ),
                accessorKey: 'dimension',
                width: '10%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: (cellProps) => {
                    const dimension = cellProps.row.original.dimensions;

                    return (

                        <div className="d-flex text-left">
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {dimension}
                            </span>

                        </div>
                    );
                },

            },
            {
                header: (
                    <div className="text-left">

                    </div>
                ),
                width: '20%',

                accessorKey: 'incm',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const incm = cellProps.row.original.incm;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-left">

                            <Row>

                                <div className="align-items-left">{incm}</div>
                            </Row>

                        </div >
                    );
                },
            },
            // {
            //     header: (
            //         <div className="text-center">
            //         </div>
            //     ),
            //     width: '10%',

            //     accessorKey: 'dimensions1',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: (cellProps) => {
            //         const dimesions1 = cellProps.row.original.dimensions1;

            //         return (
            //             <div className="text-right">

            //                 <Row>

            //                     <div className="text-bold">{dimesions1}</div>
            //                 </Row>


            //             </div >
            //         );
            //     },
            // },

            {
                header: (
                    <div className="text-center">
                    </div>
                ),
                width: '10%',

                accessorKey: 'dimensions1',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const dimesions1 = cellProps.row.original.dimensions1;

                    return (
                        <div className="d-flex">
                            <div className="text-left">
                                <Row>

                                    <div>{dimesions1}</div>
                                </Row>


                            </div >



                        </div>




                    );
                }
            },

        ],
        []
    );

    console.log('sjbdsbafjdsg', defaultvalues[0]?.def_ev_value);
    const data = [
        {
            dimensions: 'AORTIC ROOT(ED)',
            incm: templateData[0]?.rpt_echodiagram_data?.aorticroot_value,
            normal: defaultvalues[0]?.def_ev_value,
            // dimensions1: 'Left Atrium(ES)',
        },


    ];
    const data6 = [
        {
            dimensions: 'LEFT ATRIUM(ES)',
            incm: templateData[0]?.rpt_echodiagram_data?.leftatrium_value,
            normal: defaultvalues[1]?.def_ev_value
        },


    ];
    const data1 = [
        {
            dimensions: 'DIASTOLE',
            incm: templateData[0]?.rpt_echodiagram_data?.left_ventrical_diastole < 3.7 || templateData[0]?.rpt_echodiagram_data?.left_ventrical_diastole > 5.6 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{formatValue(templateData[0]?.rpt_echodiagram_data?.left_ventrical_diastole)} </span> : <span>{formatValue(templateData[0]?.rpt_echodiagram_data?.left_ventrical_diastole)}</span>,
            normal1: defaultvalues[2]?.def_ev_value,
        },
        {
            dimensions: 'SYSTOLE',
            incm: templateData[0]?.rpt_echodiagram_data?.systole_value < 1.8 || templateData[0]?.rpt_echodiagram_data?.systole_value > 4.2 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{formatValue(templateData[0]?.rpt_echodiagram_data?.systole_value)} </span> : <span>{formatValue(templateData[0]?.rpt_echodiagram_data?.systole_value)} </span>,
            normal1: defaultvalues[3]?.def_ev_value,
        },
        {
            dimensions: 'IVS (D)',
            incm:
                templateData[0]?.rpt_echodiagram_data?.ivs_value < 0.6 || templateData[0]?.rpt_echodiagram_data?.ivs_value > 1.1 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{formatValue(templateData[0]?.rpt_echodiagram_data?.ivs_value)} </span> : <span>{formatValue(templateData[0]?.rpt_echodiagram_data?.ivs_value)} </span>,
            normal1: defaultvalues[4]?.def_ev_value,
        },
        {
            dimensions: 'LVPW (D)',
            incm: templateData[0]?.rpt_echodiagram_data?.lvpw_value < 0.6 || templateData[0]?.rpt_echodiagram_data?.lvpw_value > 1.1 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}} >{formatValue(templateData[0]?.rpt_echodiagram_data?.lvpw_value)} </span> : <span>{formatValue(templateData[0]?.rpt_echodiagram_data?.lvpw_value)} </span>,
            normal1: defaultvalues[5]?.def_ev_value,
        },
        {
            dimensions: 'LV EF',
            incm:
                templateData[0]?.rpt_echodiagram_data?.lvef_value < 55 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{templateData[0]?.rpt_echodiagram_data?.lvef_value} </span> : <span>{templateData[0]?.rpt_echodiagram_data?.lvef_value} </span>,
            normal1: defaultvalues[6]?.def_ev_value,
        },


    ];
    const data7 = [
        {
            dimensions: 'DIASTOLE',
            incm:
                templateData[0]?.rpt_echodiagram_data?.right_ventrical_diastole < 0.7 || templateData[0]?.rpt_echodiagram_data?.right_ventrical_diastole > 2.3 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{formatValue(templateData[0]?.rpt_echodiagram_data?.right_ventrical_diastole)} </span> : <span>{formatValue(templateData[0]?.rpt_echodiagram_data?.right_ventrical_diastole)} </span>,
            normal1: defaultvalues[7]?.def_ev_value,
        },



    ];

    const mitral_editor_value_match = templateData[0]?.rpt_doopler_data?.mitral_editor_value.match(regex)
    const aortic_editor_value_match = templateData[0]?.rpt_doopler_data?.aortic_editor_value.match(regex)
    const tricuspid_editor_value_match = templateData[0]?.rpt_doopler_data?.tricuspid_editor_value.match(regex2)
    const pulmonary_editor_value_match = templateData[0]?.rpt_doopler_data?.pulmonary_editor_value.match(regex3)

    const data2 = [
        {
            dimensions: 'MITRAL',
            E: "E:" + " " + templateData[0]?.rpt_doopler_data?.e_value + " CM/S",
            A: "A:" + " " + templateData[0]?.rpt_doopler_data?.a_value + " CM/S",
            EA: templateData[0]?.rpt_doopler_data?.e_divide_a_value < 1 ?<span> E/A: <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{templateData[0]?.rpt_doopler_data?.e_divide_a_value}</span></span> : <span> E/A:  {templateData[0]?.rpt_doopler_data?.e_divide_a_value}</span>,
            // normal: 'E/A:0.68',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_doopler_data?.mitral_editor_value }} />,
        },
        {
            dimensions: 'AORTIC',
            E: templateData[0]?.rpt_doopler_data?.aortic > 10 ?  <span>PG:  <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{templateData[0]?.rpt_doopler_data?.aortic} </span> mm/Hg</span>:<span>PG: {templateData[0]?.rpt_doopler_data?.aortic}  mm/Hg</span>,
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_doopler_data?.aortic_editor_value }} />,
        },
        {
            dimensions: 'TRICUSPID',
            E:  templateData[0]?.rpt_doopler_data?.tricuspid > 10 ?  <span>PG:  <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{templateData[0]?.rpt_doopler_data?.tricuspid} </span> mm/Hg</span>:<span>PG: {templateData[0]?.rpt_doopler_data?.tricuspid}  mm/Hg</span>, 
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_doopler_data?.tricuspid_editor_value }} />,

        },
        {
            dimensions: 'PULMONARY',
            E: templateData[0]?.rpt_doopler_data?.pulmonary > 10 ?  <span>PG:  <span style={{fontFamily:'DejaVu Sans Condensed Bold'}}>{templateData[0]?.rpt_doopler_data?.pulmonary} </span> mm/Hg</span>:<span>PG: {templateData[0]?.rpt_doopler_data?.pulmonary}  mm/Hg</span>,

            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_doopler_data?.pulmonary_editor_value }} />,

        },
    ];

    const data4 = [
        {
            dimensions: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_left_ventricle?.leftventrical_editor_value }} />
        },
    ];

    const data5 = [
        {
            dimensions: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_impression?.impression_editor_value }} />
        },
    ];

    const data3 = [
        {
            dimensions: 'MITRAL VALVE',
            incm: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.mitralvalue_editor_value }} />,
            normal: 'LEFT ATRIUM',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.leftatrium_editor_value }} />,
        },
        {
            dimensions: 'AORTIC VALVE',
            incm: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.aorticvalue_editor_value }} />,
            normal: 'RIGHT ATRIUM',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.rightatrium_editor_value }} />,
        },
        {
            dimensions: 'TRICUSPID VALVE',
            incm: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.tricuspidvalue_editor_value }} />,
            normal: 'RIGHT VENTRICAL',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.rightventical_editor_value }} />,
        },
        {
            dimensions: 'PULMONARY VALVE',
            incm: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.pulmonaryvalue_editor_value }} />,
            normal: 'GREAT VESSELS',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.greatvessels_editor_value }} />,
        },
        {
            dimensions: 'IAS & IVS VALUE',
            incm: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.ias_editor_value }} />,
            normal: 'PERICARDIUM',
            dimensions1: <div className="editor-value" dangerouslySetInnerHTML={{ __html: templateData[0]?.rpt_color_doopler_data?.percardium_editor_value }} />,
        },
    ];

    const openPdfInNewTab = (pdfData) => {
        // Create a Blob from the PDF data
        const blob = new Blob([pdfData], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);

        // Open the Blob URL in a new tab
        window.open(blobUrl, '_blank');

        // Optionally, revoke the Object URL after a delay to clean up resources
        setTimeout(() => URL.revokeObjectURL(blobUrl), 10000); // 10 seconds delay
    };


    async function getPrintSettings() {
        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.GETPRINTSETTINGS, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportNo();
                    }
                }
            }

            if (response?.data?.body?.printSettingList) {
                // validation.values.reportno = response?.data?.body?.printSettingList
                setReffererOptions(response?.data?.body?.printSettingList)
                var default_referrer = response?.data?.body?.printSettingList?.find(e => e.rpt_prs_default == 1);

                validation.setFieldValue("selectedreferrername", {
                    label: `${default_referrer.rpt_prs_referrer_name} (${default_referrer.rpt_prs_specialization})`,
                    value: default_referrer.rpt_prs_id,
                });
                setselectedReferrerName({
                    label: `${default_referrer.rpt_prs_referrer_name} (${default_referrer.rpt_prs_specialization})`,
                    value: default_referrer.rpt_prs_id,
                })
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }
    }

    const handledrchanges = (inputValue) => {
        // setSearchInput(inputValue);
        if (inputValue.length > 2) {
            const filtered = reffererOptions.filter(hp =>
                hp.rpt_prs_referrer_name.toLowerCase().startsWith(inputValue.toLowerCase().trim().slice(0, 3))
            )
            setDrFilter(filtered)
        }
    };

    const handleSelectReferrer = (selectedOption) => {
        setselectedReferrerName(selectedOption);
    };

    const [referrer, setReferrer] = useState('');
    const [specialization, setSpecialization] = useState('');
    const [printheaderImage, setPrintHeaderImage] = useState('');
    const [printSignImage, setPrintSignImage] = useState('');

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectedreferrername: '',
        },
        validationSchema: Yup.object({
            selectedreferrername: Yup.object().required("Please select doctor name"),
        }),
        onSubmit: (values, { }) => {
            console.log('data', data);
            var data = reffererOptions.filter(item => item.rpt_prs_id === selectedreferrername.value)
            console.log('data', data);
            setReferrer(data[0].rpt_prs_referrer_name)
            setSpecialization(data[0].rpt_prs_specialization)
            setPrintHeaderImage(data[0].rpt_header_image)
            setPrintSignImage(data[0].rpt_sign_image)
            setTimeout(() => navigate(generatePDF()), 200);
            // saveReportData()
        },
    });

    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
        resetForm()
    };

    const resetForm = () => {
        validation.setFieldValue("selectedreferrername", null)
        validation.values.selectedreferrername = ''
        validation.setTouched("selectedreferrername", false)
        setselectedReferrerName({ label: 'Select', id: 0 })
        setModal(false)
    }

    // const PDFExportPageTemplate = (props) => <span style={{ textAlign: 'center', marginLeft: '295px', position: 'absolute', bottom: 0 }}>{props.pageNum}</span>
 
    const currentDateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    fileName = templateData[0]?.rpt_report_no + '_' + templateData[0]?.rpt_patient_data?.patient_name + '.pdf';
    const generatePDF = async () => {
        const base64 = await loadFontAsBase64('../../assets/fonts/DejaVuSansCondensed.ttf'); // Ensure this path is correct
        setFontBase64(base64);

        pdfExportComponent.current.save()?.then((data) => {
            const pdfBlob = new Blob([pdfExportComponent.current.value], { type: 'application/pdf' });

            // For example, you can create a URL and open it in a new tab
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
       
    });


        // pdfExportComponent.current.save();


    }



    const pageTemplate = (props) => {
        return (
            <>            <div>
                <div style={{ position: 'absolute', top: '30px', bottom: '30px', width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center', height: 50 }}>
                            {logoVisibility == true ? <img height={80} width={95} src={"/" + printheaderImage + ""}></img> : null}
                        </div>

                        <div style={{ textAlign: 'center',  marginTop: 36, marginBottom: 16, color: 'black',fontFamily:'DejaVu Sans Condensed Bold', }}>
                            <span style={{fontFamily:'DejaVu Sans Condensed Bold',
                                borderTop: '0.2px solid #0000000f', borderBottom: '0.2px solid #0000000f', fontSize: 13,   // Adjust padding to fit the text fully inside the border
                                display: 'inline-block',paddingRight:'6px !important', paddingTop:'3px !important'
                            }}>ECHOCARDIOGRAPHY REPORT</span>
                        </div>
                    </div>
                    <div style={{ marginLeft: 50, marginRight: 10 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 4 }}>
                            <div style={{ flex: 0.8, color: "#000", fontSize: 10,fontFamily:'DejaVu Sans Condensed', }}>Name : <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_patient_data?.patient_name}</span></div>
                            <div style={{ flex: 0.2, color: "#000", fontSize: 10, textAlign: 'left', float: 'left',fontFamily:'DejaVu Sans Condensed', }}>Date : {templateData[0] && formatDate(templateData[0].date)}</div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 4 }}>
                            <div style={{ fontFamily:'DejaVu Sans Condensed',flex: 0.8, color: "#000", fontSize: 10 }}><span className='me-2' >Age : {templateData[0]?.rpt_patient_data?.patient_age}</span> <span>{'       Sex : '}</span> <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_patient_data?.patient_gender_value} </span></div>
                            <div style={{fontFamily:'DejaVu Sans Condensed', flex: 0.2, color: "#000", fontSize: 10, textAlign: 'left', float: 'left' }}>Reg.No : {templateData[0]?.rpt_report_no}</div>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <div style={{ fontFamily:'DejaVu Sans Condensed',flex: 0.8, color: "#000", fontSize: 10 }}>Ref.By : Dr. <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_doctor_data?.referrer_name}
                            </span></div>

                        </div>
                    </div>


                    <div className="row mt-1" style={{ borderTop: '0.5px solid #0000000f', textAlign: 'center', fontWeight: 'bold', color: 'black' }}>
                    </div>

                </div>

            </div>
                <div style={{ width: '100%', marginTop: '30px', backgroundColor: 'red' }}>
                    {logoVisibility == true &&
                        <div className="pdf-footer" style={{ width: '90%', marginLeft: 30, marginRight: 30 }}>
                            <Row style={{ fontSize: '7px' }}>
                                <Col className="col-lg-6" style={{ fontFamily: 'DejaVu Sans Condensed Bold' , width: 'auto', color: 'black', textAlign: 'center' }}>
                                    {/* <div style={{ fontWeight: 'bold', color: 'black' }}> */}
                                    <span>Medical Centre : </span> Kasthuribai Road, Virudhunagar-TN,India.
                                    {/* </div> */}
                                </Col>
                                {/* <Col className="col-lg-1" style={{ width: 'auto', fontWeight: 'bold', color: 'black' }}> */}
                                {/* <div style={{ width: 'auto', fontWeight: 'bold', color: 'black' }}> */}
                                {/* | */}
                                {/* </div> */}
                                {/* </Col> */}
                                <Col className="col-lg-6" style={{fontFamily: 'DejaVu Sans Condensed Bold' , width: 'auto', color: 'black', textAlign: 'center' }}>
                                    {/* <div style={{ fontWeight: 'bold', color: 'black' }}> */}
                                    <span> | Surgical Centre : </span> VVVG Colony, Ramamoorthy Road, Virudhunagar - TN, India.
                                    {/* </div> */}
                                </Col>
                            </Row>
                            <div className="row mt-1" style={{ borderTop: '4px solid darkgray', textAlign: 'center', fontSize: '8px' }}>
                                {/* <hr /> */}
                                <span style={{ fontFamily: 'DejaVu Sans Condensed Bold' , color: 'black' }}>Mobile : 7338986911</span>
                            </div>
                            <Row style={{ backgroundColor: 'darkgray', fontSize: '8px' }}>
                                {/* <hr /> */}
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'start' }}>
                                    <div>
                                        info@careakps.com
                                    </div>
                                </Col>
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'center' }}>
                                    <div>
                                        www.careakps.com
                                    </div>
                                </Col>
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'end' }}>
                                    <div>
                                        www.facebook.com/careakps
                                    </div>
                                </Col>
                                {/* <Col className="col-lg-3">
                                    <div>
                                        info@careakps.com
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    }
                </div>
            </>

        );
    };

    const pageTemplate1 = (props) => {
        return (
            <>            <div>
                <div className="print-header-div" style={{ position: 'absolute', top: '30px', left: '50px', right: '10px', bottom: '30px' }}>
                    <div style={{ textAlign: 'center', height: 50 }}>
                        {logoVisibility == true ? <img height={60} width={85} src={"/" + printheaderImage + ""}></img> : null}
                    </div>

                    <div style={{ textAlign: 'center', fontWeight: 700, marginTop: 16, marginBottom: 16, color: 'black', padding: 4 }}>
                        <span style={{ borderTop: '0.2px solid #0000000f', borderBottom: '0.2px solid #0000000f', }}>ECHOCARDIOGRAPHY REPORT</span>
                    </div>
                    <table>

                        <tbody>
                            <tr>
                                <td style={{ fontSize: 10, color: 'black' }}>Name : <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_patient_data?.patient_name}</span></td>
                                <td style={{ fontSize: 10, color: 'black', float: 'right' }}>Date : {templateData[0] && formatDate(templateData[0].date)}</td>
                            </tr>
                            {/* <tr>
                                <td style={{ fontSize: 10, color: 'black' }}>Name : {templateData[0]?.rpt_patient_data?.patient_name}{'ss'}</td>
                                <td style={{ fontSize: 10, color: 'black', textAlign: 'end',justifyContent:'end',float:'right' }}>Date : {templateData[0] && formatDate(templateData[0].date)}</td>
                            </tr> */}
                            <tr>
                                <td style={{ fontSize: 10, color: 'black' }}><span className='me-2' >Age : {templateData[0]?.rpt_patient_data?.patient_age}</span> <span>{'       Sex : '}</span> <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_patient_data?.patient_gender_value} </span></td>
                                <td style={{ fontSize: 10, color: 'black', justifyContent: 'flex-end', float: 'right', marginRight: 6 }}>Reg.No : {templateData[0]?.rpt_report_no}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: 10, color: 'black' }}>Ref.By : Dr. <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_doctor_data?.referrer_name}
                                </span> </td>
                                {/* <td  style={{fontSize:12,color:'black',textAlign:'end'}}>StudyNo : </td> */}
                            </tr>
                        </tbody>
                    </table>
                    <div className="row mt-1" style={{ borderTop: '0.5px solid #0000000f', textAlign: 'center', fontWeight: 'bold', color: 'black' }}>
                    </div>

                </div>

            </div>
                <div>
                    {logoVisibility == true &&
                        <div className="pdf-footer">
                            <Row style={{ fontSize: '7px' }}>
                                <Col className="col-lg-6" style={{ fontWeight: 'bold', width: 'auto', color: 'black', textAlign: 'center' }}>
                                    {/* <div style={{ fontWeight: 'bold', color: 'black' }}> */}
                                    <span>Medical Centre : </span> Kasthuribai Road, Virudhunagar-TN,India.
                                    {/* </div> */}
                                </Col>
                                {/* <Col className="col-lg-1" style={{ width: 'auto', fontWeight: 'bold', color: 'black' }}> */}
                                {/* <div style={{ width: 'auto', fontWeight: 'bold', color: 'black' }}> */}
                                {/* | */}
                                {/* </div> */}
                                {/* </Col> */}
                                <Col className="col-lg-6" style={{ fontWeight: 'bold', width: 'auto', color: 'black', textAlign: 'center' }}>
                                    {/* <div style={{ fontWeight: 'bold', color: 'black' }}> */}
                                    <span> | Surgical Centre : </span> VVVG Colony, Ramamoorthy Road, Virudhunagar - TN, India.
                                    {/* </div> */}
                                </Col>
                            </Row>
                            <div className="row mt-1" style={{ borderTop: '4px solid darkgray', textAlign: 'center', fontSize: '8px' }}>
                                {/* <hr /> */}
                                <span style={{ fontWeight: 'bold', color: 'black' }}>Mobile : 7338986911</span>
                            </div>
                            <Row style={{ backgroundColor: 'darkgray', fontSize: '8px' }}>
                                {/* <hr /> */}
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'start' }}>
                                    <div>
                                        info@careakps.com
                                    </div>
                                </Col>
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'center' }}>
                                    <div>
                                        www.careakps.com
                                    </div>
                                </Col>
                                <Col className="col-lg-4" style={{ backgroundColor: 'darkgray', textAlign: 'end' }}>
                                    <div>
                                        www.facebook.com/careakps
                                    </div>
                                </Col>
                                {/* <Col className="col-lg-3">
                                    <div>
                                        info@careakps.com
                                    </div>
                                </Col> */}
                            </Row>
                        </div>
                    }
                </div>
            </>

        );
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 'en-GB' ensures dd-mm-yyyy format
    };
    const handleLogoCheckboxChange = () => {

        setLogoVisibility(!logoVisibility);
    };

    const reportApprove = () => {
        setApproveModal(true)
    }

    const reportReject = () => {
        setRejectModal(true)
    }

    const reportEntryApprove = async () => {
        const data = {
            user_id: Number(user.uid),
            rpt_id: Number(rptid),
            rpt_status: 2
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTAPPROVE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await reportEntryApprove();
                    }
                }
            }

            if (response?.data?.body?.statusFlag === 1) {
                console.log('bjhvhjvhjvghvhvujgiu');

                toast.success(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => navigate('/report-list'), 700);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Report Approve API Error:", error);
        }
    }

    const reportEntryReject = async () => {
        const data = {
            user_id: Number(user.uid),
            rpt_id: Number(rptid),
            rpt_status: 3
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTAPPROVE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await reportEntryReject();
                    }
                }
            }

            if (response?.data?.body?.statusFlag === 1) {
                console.log('bjhvhjvhjvghvhvujgiu', response?.data?.body?.message);

                toast.success(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => navigate('/report-request'), 700);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Report Approve API Error:", error);
        }
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content" style={{ height: '100%', fontFamily:'DejaVu Sans Condensed !important' }}>
                <ApproveModal
                    show={approveModal}
                    onDeleteClick={reportEntryApprove}
                    onCloseClick={() => setApproveModal(false)}
                />
                <RejectModal
                    show={rejectModal}
                    onDeleteClick={reportEntryReject}
                    onCloseClick={() => setRejectModal(false)}
                />
                {isLoading ?
                    <Spinners setLoading={setLoading} />
                    : <>
                        <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 16, color: 'black', }}>
                            {/* <div><img src="/care-akps-hospital-virudhunagar-logo.png" height={50} width={35}></img></div>   */}
                            <div style={{ padding: 4, marginTop: 16 }}>
                                <span style={{ borderTop: '0.2px solid #0000000f', borderBottom: '0.2px solid #0000000f', }}>ECHOCARDIOGRAPHY REPORT</span>
                            </div>

                        </div>
                        <table>

                            <tbody>
                                <tr>
                                    <td style={{ fontSize: 10, color: 'black', textTransform: 'uppercase' }}>Name : {templateData[0]?.rpt_patient_data?.patient_name}</td>
                                    <td style={{ fontSize: 10, color: 'black', textAlign: 'end', justifyContent: 'end' }}>Date : {templateData[0] && formatDate(templateData[0].date)}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: 10, color: 'black' }}><span className='me-2' >Age : {templateData[0]?.rpt_patient_data?.patient_age} </span>    <span>{' '} Sex : <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_patient_data?.patient_gender_value} </span></span></td>
                                    <td style={{ fontSize: 10, color: 'black', textAlign: 'end', justifyContent: 'end' }}>Reg.No : {templateData[0]?.rpt_report_no}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: 10, color: 'black' }}>Ref.By : DR. <span style={{ textTransform: 'uppercase' }}>{templateData[0]?.rpt_doctor_data?.referrer_name}
                                    </span> </td>
                                    {/* <td  style={{fontSize:12,color:'black',textAlign:'end'}}>StudyNo : </td> */}
                                </tr>
                            </tbody>
                        </table>

                        <div ref={contentToPrint} className="container-fluid">



                            <PDFExport style={{fontFamily:'DejaVu Sans Condensed !important'}}  forcePageBreak=".page-break" className="page dejaVu-font" pageTemplate={pageTemplate} paperSize="A4" margin={{ top: 200, bottom: 40 }} ref={pdfExportComponent} fileName={fileName}>
                            <div className="page" style={{ fontFamily: 'DejaVu Sans Condensed' }}>
                                <div style={{ textAlign: 'center', marginBottom: 8, marginTop: 16, color: 'black' }}>
                                    <span style={{ fontSize: 10 ,fontFamily: 'DejaVu Sans Condensed Bold' }}>M-MODE ECHODIAGRAM REPORT</span>
                                </div>
                                {/* <style>
                    {`
                    @font-face {
                        font-family: 'DejaVu Sans Condensed';
                        src: ${fontBase64} format('truetype');
                    }
                    `}
                </style> */}

                                <Row style={{
                                    marginLeft: 40, right: 10, width: '90%'
                                }} className="col-12">
                                    <Col className="col-lg-6" style={{ paddingRight: '0px' }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{fontFamily: 'DejaVu Sans Condensed Bold' , borderTop: '1px solid #d3d3d3',  width: '100px', backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5" }} className="print-th-fit ">DIMENSIONS</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' , backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5", fontSize: 9,  width: 'fit-content !important' }}>VALUE</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' ,  backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', width: 'fit-content !important' }}>NORMAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map((row, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ width: '100px', borderTop: '1px solid #d3d3d3', }} className="print-th-fit text-black">{row.dimensions}</td>
                                                                <td style={{ width: 'fit-content !important' }} className="print-td-fit text-black">{row.incm < 2.0 || row.incm > 3.7 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}} className=" text-black">{formatValue(row.incm)} </span> : <span>{formatValue(row.incm)} </span>} </td>
                                                                <td className="print-td-fit text-black">{row.normal}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col className="col-lg-6" style={{ padding: '0px' }}>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontFamily: 'DejaVu Sans Condensed Bold' ,width: '100px', backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5", }} className="print-th-fit ">DIMENSIONS</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' , backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5", fontSize: 9,  width: '50px !important' }}>VALUE</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' , backgroundColor: '#eee', color: '#000', borderColor: "#d5d5d5", fontSize: 9,  width: '80px !important' }}>NORMAL</th>
                                                    {/* <th className="print-th" style={{ fontSize: 10, fontWeight: 'bold' }}>DIMENSIONS</th>
                                                    <th className="print-th" style={{ fontSize: 10, fontWeight: 'bold' }}>VALUE</th>
                                                    <th className="print-th" style={{ fontSize: 10, fontWeight: 'bold' }}>NORMAL</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data6.map((row, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ width: '100px' }} className="print-td-fit text-black">{row.dimensions}</td>
                                                                <td className="print-td text-black">{row.incm < 1.9 || row.incm > 4.0 ? <span style={{fontFamily:'DejaVu Sans Condensed Bold'}} className=" text-black">{formatValue(row.incm)} </span> : <span>{formatValue(row.incm)} </span>}</td>
                                                                <td className="print-td text-black">{row.normal}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>


                                <Row style={{
                                    marginLeft: 40, right: 10, width: '90%', marginTop: '8px'
                                }} className="col-12" >
                                    <Col className="col-lg-6" style={{ paddingRight: '0px' }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="print-th-fit" style={{fontFamily: 'DejaVu Sans Condensed Bold' ,  width: '100px', borderTop: '1px solid #d3d3d3', backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>LEFT VENTRICLE</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' ,  backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>VALUE</th>
                                                    <th className="print-th" style={{ fontFamily: 'DejaVu Sans Condensed Bold' ,backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>NORMAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data1.map((row, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ width: '100px', borderTop: '1px solid #d3d3d3', }} className="print-td-fit text-black">{row.dimensions}</td>
                                                                <td className="print-td text-black">{row.incm} </td>
                                                                <td className="print-td text-black">{row.normal1}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col className="col-lg-6" style={{ padding: '0px' }}>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr>
                                                    <th className="print-th-fit" style={{fontFamily: 'DejaVu Sans Condensed Bold' , width: '100px',  backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>RIGHT VENTRICLE</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' , backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>VALUE</th>
                                                    <th className="print-th" style={{fontFamily: 'DejaVu Sans Condensed Bold' ,  backgroundColor: '#eee', borderColor: "#d5d5d5", fontSize: 9, fontWeight: 'bold', color: '#000', }}>NORMAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data7.map((row, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td style={{ width: '100px' }} className="print-td-fit text-black">{row.dimensions}</td>
                                                                <td className="print-td-fit text-black">{row.incm}</td>
                                                                <td className="print-td-fit text-black">{row.normal1}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                          
                                <div style={{ textAlign: 'center', marginBottom: 8, marginTop: 16, color: 'black' }}>
                                    <span style={{ fontSize: 10 ,fontFamily: 'DejaVu Sans Condensed Bold' }}>DOPPLER REPORT AND DOPPLER WAVEFORM ANALYSIS</span>
                                </div>
                                <Row style={{
                                    marginLeft: 40, right: 10, width: '90%'
                                }} className="col-12">
                                    <Col className="col-lg-12" style={{ paddingRight: '0px' }}>


                                        <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr >
                                                    <th colSpan={5} className="print-th-fit" style={{fontFamily: 'DejaVu Sans Condensed Bold' , borderTop: '1px solid #d3d3d3 !important', backgroundColor: '#eee', borderColor: "#d5d5d5", color: '#000', fontSize: 9, fontWeight: 'bold' }}>VALVE</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data2.map((row, index) => {

                                                        return (

                                                            <tr style={{ display: 'flex', minWidth: '1040px' }} key={index}>
                                                                <td style={{ minWidth: '100px' }} className="print-td-fit text-black">{row.dimensions}</td>

                                                                <td style={{ minWidth: '80px' }} className="print-td-fit text-black">{row.E}</td>
                                                                {row.dimensions == "MITRAL" ? <td style={{ minWidth: '75px' }} className="print-td-fit text-black">{row.A}</td> : null}
                                                                {row.dimensions == "MITRAL" ? <td style={{ minWidth: '75px' }} className="print-td-fit text-black">{row.EA}</td> : null}
                                                                {row.dimensions == "MITRAL" ? <td style={{ minWidth: '192px', width: '192px', overflowWrap: 'break-word' }} className="print-td-fit text-black">{row.dimensions1}</td> : <td style={{ minWidth: '342px' }} colSpan={3} className="print-td-fit text-black">{row.dimensions1}</td>}

                                                            </tr>

                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>

                                </Row>


                                <div style={{
                                    pageBreakBefore: 'always', breakBefore: 'always', textAlign: 'center', marginBottom: 8, marginTop: 16, color: 'black'
                                }}>
                                    <span style={{ fontSize: 10, fontFamily:'DejaVu Sans Condensed Bold' }}>2D & COLOR FLOW DOPPLER REPORT</span>
                                </div>
                                <Row style={{
                                    marginLeft: 40, right: 9, width: '90%', pageBreakBefore: 'always', breakBefore: 'always'
                                }} className="col-12">
                                    <Col className="col-lg-12" style={{ paddingRight: '0px' }}>


                                        <table >

                                            <tbody style={{ border: '1px solid #d3d3d3' }}>
                                                {data3.map((row, index) => (

                                                    <React.Fragment key={index}>

                                                        <tr key={index}>
                                                            <td style={{ width: '100px' }} className="print-td-fit text-black">{row.dimensions}</td>
                                                            <td style={{ width: '200px' }} className="print-td-fit text-black">{row.incm}</td>
                                                            <td style={{ width: '100px' }} className="print-td-fit text-black">{row.normal}</td>
                                                            <td style={{ width: 'fit-content' }} className="print-td-fit text-black">{row.dimensions1}</td>
                                                        </tr>


                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col></Row>

                                <Row className="col-12 page-break" style={{
                                    marginLeft: 40, right: 10, width: '90%', marginTop: 16, pageBreakBefore: 'always', breakBefore: 'always'
                                }}>
                                    <Col className="col-lg-12" style={{ paddingRight: '0px' }}>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontFamily: 'DejaVu Sans Condensed Bold' ,backgroundColor: '#eee', borderColor: "#d5d5d5", color: '#000', fontSize: 9 }} className="print-th text-black">LEFT VENTRICLE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data4.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="print-td-fit text-black">{row.dimensions}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col></Row>

                                <Row className="col-12" style={{
                                    marginTop: 16, marginLeft: 40, right: 10, width: '90%',

                                }}>
                                    <Col className="col-lg-12" style={{ paddingRight: '0px' }}>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontFamily: 'DejaVu Sans Condensed Bold' ,backgroundColor: '#eee',  borderColor: "#d5d5d5", color: '#000', fontSize: 9 }} className="print-th text-black">IMPRESSION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data5.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="print-td text-black">{row.dimensions}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>

                                {referrer !== '' && <Row style={{ textAlign: 'right' }}>
                                    {/* <Col className="col-lg-12">

                                        <div style={{ marginTop: '4rem' }}>
                                            <div style={{ height: 50, display: 'block', Margin: '0 auto' }}>
                                                {signVisibility == true ? <img height={50} width={45} src={"/" + printSignImage + ""}></img> : null}
                                            </div>
                                            <p style={{ fontWeight: 'bold', marginBottom: '0.5rem', marginRight: '1rem' }}>Dr.{referrer}</p>
                                            <p style={{ marginBottom: '0', fontSize: '10px', marginRight: '1rem' }}>{"(" + specialization + ")"}</p>
                                        </div>
                                    </Col> */}
                                    <Col className="container"  >
                                        <div className="content" style={{ marginTop: '4rem', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                            {signVisibility == true ? <img height={65} width={80} style={{ marginBottom: '-20px' }} alt="Description" className="centered-image" src={"/" + printSignImage + ""}></img> : null}
                                            <div style={{fontFamily: 'DejaVu Sans Condensed Bold' ,  fontSize: 12, textAlign: 'center', marginRight: "10px" }}>Dr.{referrer}</div>
                                            <div style={{ fontFamily: 'DejaVu Sans Condensed Bold' ,marginBottom: '0', fontSize: '10px', textAlign: 'center' }}>{"(" + specialization + ")"}</div>
                                        </div>
                                    </Col>
                                </Row>}

                                {/* Footer */}

</div>
                            </PDFExport>
                        </div>
                        <div className="text-end mt-3 mb-3">
                            {
                                reportStatus === 1 ? <>
                                    <Button
                                        color="info"
                                        type="submit"
                                        className="save-and-continue-user me-2"
                                        onClick={() => navigate('/report-approval')}
                                    // style={{ marginLeft: '10px' }}
                                    // disabled={isButtonDisabled}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="success"
                                        // type="submit"
                                        className="save-user me-2"
                                        onClick={() => {
                                            reportApprove()
                                        }}
                                        disabled={isButtonDisabled}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        color="danger"
                                        className="save-user"
                                        onClick={() => {
                                            reportReject()
                                        }}
                                        disabled={isButtonDisabled}
                                    >
                                        Reject
                                    </Button></> : <>
                                    <Button
                                        color="warning"
                                        className="save-user me-2"
                                        onClick={() => {

                                            var default_referrer = reffererOptions?.find(e => e.rpt_prs_default == 1);

                                            validation.setFieldValue("selectedreferrername", {
                                                label: `${default_referrer.rpt_prs_referrer_name} (${default_referrer.rpt_prs_specialization})`,
                                                value: default_referrer.rpt_prs_id,
                                            });
                                            setselectedReferrerName({
                                                label: `${default_referrer.rpt_prs_referrer_name} (${default_referrer.rpt_prs_specialization})`,
                                                value: default_referrer.rpt_prs_id,
                                            })
                                            setModal(true)
                                        }}
                                    >
                                        Generate PDF
                                    </Button>
                                    <Button
                                        color="info"
                                        type="submit"
                                        className="save-and-continue-user me-2"
                                        onClick={() => navigate('/report-list')}
                                    // style={{ marginLeft: '10px' }}
                                    // disabled={isButtonDisabled}

                                    >
                                        Close
                                    </Button></>
                            }
                        </div></>
                }
            </div>

            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle} tag="h4">
                    Print Settings
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col className="col-12">
                            <div className="mb-3">
                                <Label>Doctor Name<span style={{ color: 'red' }}>*</span></Label>
                                <Select
                                    value={validation.values.selectedreferrername}
                                    onChange={(selectedOption) => {
                                        handleSelectReferrer(selectedOption);
                                        validation.setFieldValue("selectedreferrername", selectedOption);
                                    }}
                                    onInputChange={handledrchanges}

                                    options={reffererOptions.map((hp) => ({
                                        label: `${hp.rpt_prs_referrer_name} (${hp.rpt_prs_specialization})`,
                                        value: hp.rpt_prs_id,
                                    }))}
                                    className={`select2-selection ${validation.touched.selectedreferrername && validation.errors.selectedreferrername ? 'is-invalid' : ''}`}
                                    invalid={validation.touched.selectedreferrername && validation.errors.selectedreferrername}
                                />
                                {validation.touched.selectedreferrername && validation.errors.selectedreferrername ? (
                                    <FormFeedback type="invalid">{validation.errors.selectedreferrername}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <label style={{ marginRight: 8 }}> Print Header ?   </label>
                            <Label check>
                                <Input
                                    // id={"logoVisibility"}
                                    // name={"logoVisibility"}
                                    type="checkbox"
                                    // disabled={cellProps?.row?.original?.view ? false : true}
                                    checked={logoVisibility}
                                    onClick={handleLogoCheckboxChange}
                                />
                            </Label>
                        </Col>
                        {/* <Col xs={6}>
                            <label style={{ marginRight: 8 }}> Need to print Sign ?   </label>
                            <Label check>
                                <Input
                                
                                    type="checkbox"
                                   
                                    checked={signVisibility}
                                    onClick={() => {
                                        setSignVisibility(!signVisibility)
                                    }}
                                />
                            </Label>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <Button
                                    color="success"
                                    type="submit"
                                    className="save-user"
                                    onClick={() => {
                                        validation.handleSubmit()
                                    }
                                    }
                                // disabled={isButtonDisabled}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="secondary"
                                    className="save-and-continue-user"
                                    onClick={() => resetForm()}
                                    style={{ marginLeft: '10px' }}
                                // disabled={isButtonDisabled}
                                >
                                    Close
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>


        </React.Fragment >
    );
};

ReportList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ReportList);
