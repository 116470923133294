import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logos from "../../assets/images/short-logo.png";
import logoLightPng from "../../assets/images/care-akps-hospital-virudhunagar-logo.jpg";
import logoLightSvg from "../../assets/images/common_logo.jpg";
import logoDark from "../../assets/images/logo-dark.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{ backgroundColor: '#FFF' }}>
          <Link to="/report-list" className="logo logo-light">
            <span className="logo-sm">
              <img src={logos} alt="" height="18" />
            </span>
            <span style={{fontSize:20,fontWeight:500}} className="logo-lg">
              ECHO REPORTS
              {/* <img src={logoLightSvg} alt="" height="60" /> */}
            </span>
          </Link>
          {/* <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="60" width="220" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="60" width="220" />
            </span>
          </Link> */}
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
