import PropTypes from "prop-types";
import React, { useState } from "react";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// End point
import * as api from "../../pages/Authentication/api/index"
import './Auth.scss'
// import images
import CarouselPage from "./signin";
import logoLightSvg from "../../assets/images/common_logo.jpg";
import logoLighttriosSvg from "../../assets/images/trios-logo.jpg";
import * as constants from "../../constants/layout"

const Login = props => {

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  //meta title
  document.title = "Login | Care AKPS";

  const [passwordShow, setPasswordShow] = useState(false);
  let navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your LoginID"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      const req_data = {
        "username": values?.email,
        "password": values?.password
      }
      login(req_data)
      setButtonDisabled(true)
    }
  });

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const login = async (req) => {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.LOGIN, req)
      setButtonDisabled(false)
      if (response && response?.data?.body?.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(response?.data?.body?.UserInfo));
        toast.success(response?.data?.body?.message, { autoClose: 2000 });
        // navigate('/report-list');
        if(response?.data?.body?.UserInfo?.u_urid =='2' || response?.data?.body?.UserInfo?.u_urid =='4' || response?.data?.body?.UserInfo?.u_urid =='7')
        {
          setTimeout(() => navigate('/report-approval'), 700);
        }
        else
        {  setTimeout(() => navigate('/report-list'), 700);

        }
      
      } else if (response && response?.data?.body?.status === 401) {
        toast.error(response?.data?.body?.message, { autoClose: 2000 });
      } else {
        toast.error(response?.data?.body?.message, { autoClose: 2000 });
      }
    } catch (err) {
      setButtonDisabled(false)
      console.error('err', err)
    }
  }
  // Login API Calling


  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4" style={{ backgroundColor: '#FFFFFF' }}>
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2 mb-md-5">
                      <Link to="" className="d-block card-logo">
                        <img
                          src={logoLightSvg}
                          alt=""
                          height="180"
                          width="300"
                          className="logo-dark-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h4 className="text-warning text-center text-bold" >ECHO Reports</h4>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                          }}

                        >
                          <div className="mb-3">
                            <Label className="form-label">Login ID</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Login ID"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                maxLength={constants.textboxLength.u_password}
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              {passwordShow == false && <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                              </button>}
                              {passwordShow == true && <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <span className="mdi mdi-eye-off-outline"></span>
                              </button>}
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          <div className="mt-3 d-grid">
                            <button

                              className="btn btn-block custom-btn-color  "
                              type="submit"
                              disabled={isButtonDisabled}
                            >
                              Log In
                            </button>
                          </div>

                        </Form>

                      </div>
                    </div>
                    <div>

                      {/* <div className="mt-4 mt-md-5 text-center">
                        <img
                          src={logoLighttriosSvg}
                          alt=""
                          height="30"
                          width="60"
                          className="logo-dark-element"
                        />
                      </div> */}
                    </div>

                    <div className="mt-4 mt-md-5 text-center" style={{ alignItems: "center", display: "flex", flexDirection: 'column' }}>
                      <div>

                        <img
                          src={logoLighttriosSvg}
                          alt=""
                          height="30"
                          width="60"
                          className="logo-dark-element"
                        />
                      </div>
                      <p className="foter">
                        © {new Date().getFullYear()}. Powered
                        by
                        <a style={{ color: 'red' }} onClick={() => openInNewTab('https://trio-s.com/')} href="#"> Trio-S</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
